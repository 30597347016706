import styled, {css} from 'styled-components';

import {flexCenter} from '~/shared/theme/FlexLayout';
import {body14Bold} from '~/shared/theme/typography';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';
import LogoIcon from '~/assets/images/icons/10bisPlusIcon.svg?react';
import {getLocalizationService} from '~/shared/services/localisationService';

const freeDeliveryStyles = css`
  ${flexCenter}
  background-color: ${({theme}) => theme.tagColorsBackground.NoDeliveryFee};
  padding: 0 4px;
  height: 22px;
  ${body14Bold}
  color: ${({theme}) => theme.tagColorsForeground.NoDeliveryFee};
`;

const FreeDeliveryTitle = styled.div`
  ${flexCenter}
  ${freeDeliveryStyles}
  border-radius: 2px;
`;

const StyledLogoIcon = styled(LogoIcon)`
  ${flipOnLTR`
    margin-left: 4px;
  `}
`;

const FreeDeliveryTag = () => {
  const {t} = getLocalizationService();

  return (
    <FreeDeliveryTitle>
      <StyledLogoIcon />
      {t('free_delivery')}
    </FreeDeliveryTitle>
  );
};

export default FreeDeliveryTag;
