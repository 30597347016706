import {memo} from 'react';

import {useDispatch} from 'react-redux';
import styled from 'styled-components';

import {getLocalizationService} from '~/shared/services/localisationService';
import actions from '~/shared/store/actions';
import {media} from '~/shared/theme/utils';
import {trackEvent} from '~/shared/services/analytics';
import {Padder, SubPageTitle, SharedCheckoutCloseButton} from '~/shared/components/styled';
import ImageWithAlt from '~/shared/components/ImageWithAlt';
import {LayoutWithHeader} from '~/shared/components/LayoutWithHeader';
import {AddCreditCardModes} from '~/shared/consts/checkoutConsts';
import {PaymentMethodTypes} from '~/shared/consts/paymentConsts';
import pciLogoUrl from '~/assets/images/pci-logo.png';
import {flexColumn} from '~/shared/theme/FlexLayout';
import {body12Normal} from '~/shared/theme/typography';
import {handleRefreshToken, is401Error} from '~/shared/services/auth';
import {Payment} from '~/shared/store/models';
import {isAvailablePayments} from '~/shared/utils/payments';

import Button from '../Button';

import AddCreditCardIFrame from './AddCreditCardIFrame';

const CreditCardWrapper = styled.div<{extendCreditCardFormWidth?: boolean}>`
  ${flexColumn}
  width: 90vw;
  margin: 10px auto;
  ${media.minMobile`
    width: 410px;
    margin: 0px auto;
  `}
  ${({extendCreditCardFormWidth}) => extendCreditCardFormWidth && media.minTablet`
    width: 514px;
  `}
`;

const CloseButton = styled(Button)`
  width: 18px;
  height: 18px;
  margin: 10px 0;
`;

const StyledCloseSvg = styled(SharedCheckoutCloseButton)`
  position: inherit;
`;

const SecurityComment = styled.div`
  align-self: center;
  ${body12Normal}
  margin-top: 8px;
  color: ${({theme}) => theme.colors.text};
`;

const PciLogo = styled(ImageWithAlt).attrs({
  src: pciLogoUrl,
})`
  width: 100px;
  margin: 15px auto 0 auto;
`;

const OneLineContainer = styled.div`
  ${flexColumn}
  flex: 1;
`;

const OneLineModeBlock = ({children, onClose}: {children?: React.ReactNode; onClose?: () => void}) => {
  const {t} = getLocalizationService();

  return (
    <OneLineContainer>
      <CloseButton aria-label={t('close')} onClick={onClose}>
        <StyledCloseSvg />
      </CloseButton>
      {children}
      <SecurityComment>{t('security_comment')}</SecurityComment>
      <PciLogo alt="Pci logo" />
    </OneLineContainer>
  );
};

const DefaultModeBlock = ({children, onBackLinkClick, openCheckout, withoutHeader}: {
  children?: React.ReactNode;
  onBackLinkClick?: () => void;
  openCheckout?: (() => void) | null;
  withoutHeader?: boolean;
}) => {
  const onBackClick = onBackLinkClick || openCheckout;

  if (!withoutHeader) {
    return (
      <LayoutWithHeader onClick={onBackClick} titleKey="add_credit_card" showBackArrow titleComponent={SubPageTitle}>
        <Padder>
          <CreditCardWrapper>
            {children}
          </CreditCardWrapper>
        </Padder>
      </LayoutWithHeader>
    );
  }

  return (
    <CreditCardWrapper extendCreditCardFormWidth>{children}</CreditCardWrapper>
  );
};

const AddCreditCard = ({onSuccess, onClose, onBackLinkClick, openCheckout = null, mode = null, withoutHeader}: {
  onSuccess?: (newCreditCard?: Payment) => void;
  onClose?: () => void;
  onBackLinkClick?: () => void;
  openCheckout?: (() => void) | null;
  mode?: AddCreditCardModes | null;
  withoutHeader?: boolean;
}) => {
  const dispatch = useDispatch();

  const handleSuccess = async (data: {
    last4Digits: string;
  }) => {
    try {
      const payments = await dispatch(actions.getAvailablePayments());

      if (!isAvailablePayments(payments)) {
        return;
      }

      const availablePayment = payments?.availablePayments?.find(
        card => card.cardLastDigits === data.last4Digits && card.paymentMethod === PaymentMethodTypes.CREDIT_CARD,
      );

      if (availablePayment) {
        dispatch(actions.addPayment({...availablePayment, assigned: true, isDisabled: false}));
      }
      trackEvent('hasSuccessfullyAddedCreditCard');
      onSuccess?.(availablePayment);
    } catch (e) {
      if (is401Error(e)) {
        await handleRefreshToken(e, handleSuccess, data);
      }
    }
  };

  const BlockComp = mode === AddCreditCardModes.ONE_LINE ? OneLineModeBlock : DefaultModeBlock;
  return (
    <BlockComp
      onClose={onClose}
      onBackLinkClick={onBackLinkClick}
      openCheckout={openCheckout}
      withoutHeader={withoutHeader}
    >
      <AddCreditCardIFrame onSuccess={handleSuccess} mode={mode} />
    </BlockComp>
  );
};

export default memo(AddCreditCard);
