import {removeCookie} from '~/shared/utils/cookies';
import {
  CONTEXT_COOKIE_NAME,
  NEXT_CONTEXT_COOKIE_NAME,
  LOCAL_COOKIE_NAME,
  LAST_LAT_LNG_COOKIE_NAME,
} from '~/shared/consts/commonConsts';
import {getLegacySiteUrl} from '~/shared/services/urlService';
import {pushRoute, getCurrentLocation} from '~/shared/router';
// @ts-expect-error config needs to be rewritten as ts
import {basedir} from '~/shared/config';
import {createLogger} from '~/shared/logging';
import {clearReleventStorageData} from '~/shared/store/persistStore/utils';
import apiService from '~/shared/services/apiService';
// eslint-disable-next-line @welldone-software/modules-engagement
import {onLogout as onLogoutIntercom} from '~/app/components/SupportChat';
import store from '~/shared/store';
import actions from '~/shared/store/actions';
import refreshQueueSingleton from '~/shared/services/auth/RefreshQueue';

import {isApiError} from '../apiErrorService';
import {getRefreshToken} from '../bffService/api';

const logger = createLogger('auth');
const INVALID_LOGIN_MSG = 'Invalid facebook login';
const ACCOUNT_ERROR_MSG = 'Problem getting facebook user for account';

const MAXIMUM_LOCK_DURATION = 15 * 1000;
const REFRESH_DELAY_ATTEMPTS_TO_RETRY = [2, 3, 5];
const IS_IDLE_LOCAL_STORAGE_KEY = 'handleRefreshIdle';
const LAST_REFRESH_TIMESTAMP_LOCAL_STORAGE_KEY = 'lastRefreshTimestamp';
const LAST_LOCKER_ACQUIRE_TIME_STORAGE_KEY = 'lastRefreshLockerAcquire';

enum LockerOperatorAction {
  LOCK,
  UNLOCK
}

const lockerOperator = (action: LockerOperatorAction) => {
  if (action === LockerOperatorAction.LOCK) {
    localStorage.setItem(IS_IDLE_LOCAL_STORAGE_KEY, 'false');
    localStorage.setItem(LAST_LOCKER_ACQUIRE_TIME_STORAGE_KEY, `${new Date().getTime()}`);
  }

  if (action === LockerOperatorAction.UNLOCK) {
    localStorage.setItem(IS_IDLE_LOCAL_STORAGE_KEY, 'true');
    localStorage.removeItem(LAST_LOCKER_ACQUIRE_TIME_STORAGE_KEY);
  }
};

const shouldRemoveLock = (acquireTimeFromStorage: string) => {
  const timestamp = Number(acquireTimeFromStorage);
  if (!timestamp) {
    return true;
  }

  return new Date().getTime() - timestamp > MAXIMUM_LOCK_DURATION;
};

const isIdle = () => new Promise<{isHandlerIdle: boolean; isRecentlyRefreshed: boolean}>(resolve => {
  // is idle flag in local storage
  const isIdleInStorage = localStorage.getItem(IS_IDLE_LOCAL_STORAGE_KEY);
  // last update timestamp (if exists)
  const lastUpdateTimeInStorage = localStorage.getItem(LAST_REFRESH_TIMESTAMP_LOCAL_STORAGE_KEY);

  if (isIdleInStorage === 'false') {
    const lastLockerAcquireTime = localStorage.getItem(LAST_LOCKER_ACQUIRE_TIME_STORAGE_KEY);
    if (!lastLockerAcquireTime || shouldRemoveLock(lastLockerAcquireTime)) {
      lockerOperator(LockerOperatorAction.UNLOCK);
      resolve({
        isHandlerIdle: true,
        isRecentlyRefreshed: false,
      });
      return;
    }

    const idleTimeout = setTimeout(() => {
      lockerOperator(LockerOperatorAction.UNLOCK);
    }, MAXIMUM_LOCK_DURATION);
    // creating storage listener
    const storageListener = (e: StorageEvent) => {
      if (e.key === IS_IDLE_LOCAL_STORAGE_KEY && e.newValue === 'true') {
        // getting current last update timestamp
        const currentLastUpdateTimeInStorage = localStorage.getItem(LAST_REFRESH_TIMESTAMP_LOCAL_STORAGE_KEY);
        window.removeEventListener('storage', storageListener);
        clearTimeout(idleTimeout);
        resolve({
          isHandlerIdle: true,
          // if the currentLastUpdateTime is different from lastUpdateTime, the refresh is already occurred
          isRecentlyRefreshed: lastUpdateTimeInStorage !== currentLastUpdateTimeInStorage,
        });
      }
    };
    // listener appended to window
    window.addEventListener('storage', storageListener);
  }

  // if isIdleInStorage is anything but false (true/null) the handleRefreshToken is idle
  if (isIdleInStorage !== 'false') {
    resolve({
      isHandlerIdle: true,
      isRecentlyRefreshed: lastUpdateTimeInStorage ?
        new Date().getTime() - Number(lastUpdateTimeInStorage) < 1000 * 60 :
        false,
    });
  }
});

export const disconnectUserModal = () => {
  store.dispatch(
    actions.setCurrentModal('refreshTokenModal', {
      hideCloseButton: true,
    }),
  );
};

export const is401Error = (e: unknown) =>
  (isApiError(e) ? e?.status === 401 : typeof e === 'string' && e.includes('401'));

export const handleRefreshToken = async <T>(e: unknown, action?: (args: T) => any, params?: T): Promise<any> =>
  refreshQueueSingleton.enqueue(e, action, params);

export async function handleRefresh<T>(e: unknown, action?: (args: T) => any, params?: T) {
  // awaiting while the handler is idle
  const {isRecentlyRefreshed} = await isIdle();

  if (!is401Error(e)) {
    return;
  }

  try {
    // locking the handler
    lockerOperator(LockerOperatorAction.LOCK);
    // if the token was already refreshed by another tab, skipping this refresh
    if (!isRecentlyRefreshed) {
      await REFRESH_DELAY_ATTEMPTS_TO_RETRY.reduce(async (promise, _, index) => {
        const shouldAttemptRefresh = await promise;
        if (!shouldAttemptRefresh) {
          return promise;
        }

        try {
          await getRefreshToken();
          // tracking last refresh time
          localStorage.setItem(LAST_REFRESH_TIMESTAMP_LOCAL_STORAGE_KEY, `${new Date().getTime()}`);
          // Exit if successful
          return Promise.resolve(false);
        } catch (retryError) {
          if (is401Error(retryError) || index === REFRESH_DELAY_ATTEMPTS_TO_RETRY.length - 1) {
            throw retryError;
          }

          if (index < REFRESH_DELAY_ATTEMPTS_TO_RETRY.length - 1) {
            await new Promise(resolve => setTimeout(resolve, REFRESH_DELAY_ATTEMPTS_TO_RETRY[index] * 1000)); // Wait for the specified delay before retrying
          }
          return Promise.resolve(true);
        }
      }, Promise.resolve(true));
    }
  } catch (error) {
    logger.error('handleRefreshToken Failed with error', error);
    await logout('/?disconnectModal=true');
    return;
  } finally {
    // unlocking the handler
    lockerOperator(LockerOperatorAction.UNLOCK);
  }

  if (action) {
    const data = await action(params as T);
    return data;
  }
}

export async function loginWithFacebook() {
  const {userID: facebookUserId, accessToken: facebookUserAccessToken, email} = await apiService.facebook3rdPartyLogin();
  let socialResponse;
  try {
    const {data} = await apiService.facebookLogin({
      facebookUserId,
      facebookUserAccessToken,
    });
    const {socialLoginResponse, encryptedId} = data;
    const shouldConnectAccount = socialLoginResponse === 'SignatureValid';
    socialResponse = socialLoginResponse;

    if (
      socialLoginResponse === 'SignatureInvalid' ||
      (!shouldConnectAccount && socialLoginResponse !== 'LoginSuccess')
    ) {
      throw Error(INVALID_LOGIN_MSG);
    }

    return {
      shouldConnectAccount,
      facebookUserId,
      facebookUserAccessToken,
      encryptedId,
      email,
    };
  } catch (error: unknown | any) {
    if (error && socialResponse) {
      logger.error(error, {socialLoginResponse: socialResponse});
      throw new Error(error);
    } else {
      logger.error(ACCOUNT_ERROR_MSG);
      throw new Error(ACCOUNT_ERROR_MSG);
    }
  }
}

export async function logout(redirectTo?: string) {
  clearReleventStorageData();
  removeCookie(CONTEXT_COOKIE_NAME);
  removeCookie(NEXT_CONTEXT_COOKIE_NAME);

  await apiService.signOut();

  removeCookie('uid');
  removeCookie('UserData');
  removeCookie(LAST_LAT_LNG_COOKIE_NAME);
  removeCookie(LOCAL_COOKIE_NAME);

  onLogoutIntercom();

  const currentLocation = getCurrentLocation();
  // eslint-disable-next-line no-restricted-properties
  if (currentLocation.hostname !== 'www.10bis.co.il') {
    pushRoute(redirectTo || '/', {hard: true});
    return;
  }

  if (redirectTo) {
    const colonPort = currentLocation.port ? `:${currentLocation.port}` : '';
    const encodedURL = encodeURIComponent(
      `${currentLocation.protocol}//${currentLocation.hostname}${colonPort}${basedir}${redirectTo}`,
    );
    pushRoute(getLegacySiteUrl(`/Account/Logoff?redirectUrl=${encodedURL}`), {hard: true});
    return;
  }

  pushRoute(getLegacySiteUrl('/Account/Logoff'), {hard: true});
}
